export function metaTagContent(name, fallback = '') {
  return document.querySelector(`meta[name=${name}][content]`)?.content ?? fallback;
}

export function dataTagContent(parentId, name, fallback = "") {
  if (
    !parentId ||
    !document.querySelector(`#${parentId}`) ||
    !document.querySelector(`#${parentId}`).dataset[name]
  ) return fallback

  return document.querySelector(`#${parentId}`).dataset[name];
}