import { getCurrentInstance, toRaw } from 'vue';
import * as Sentry from '@sentry/browser';

export function useComponentChain() {
  const getComponentName = (component) => {
    return component.type.name || component.type.__name;
  };

  const getComponentChain = (component, chain = []) => {
    if (component) {
      const name = getComponentName(component);
      if (name) chain.push(name);
      return getComponentChain(component.parent, chain);
    }
    return chain;
  };

  const instance = getCurrentInstance();
  if (!instance) {
    console.error('useComponentChain must be called within a setup function.');
    return [];
  }

  return getComponentChain(instance).map((line, num) => `${++num}. ${line}`).join('\n');
}

export function useComponentProps() {
  const instance = getCurrentInstance();
  return JSON.stringify(toRaw(instance.props), null, 2);
}

export function logErrorOnSentry(error, componentInfo = {}, contextInfo = {}) {
  console.error(error);
  Sentry.withScope(function (scope) {
    scope.setContext('Component', componentInfo);
    scope.setContext('Context', contextInfo);
    Sentry.captureException(error);
  });
}